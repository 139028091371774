.dropdown-input-container {
  height: 65px;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  color: black;
  border: 1px solid #80808091;

  & label {
      text-align: left;
      font-size: 14px;
      color: #5A5A5A;
  }

  .dropdown-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      align-items: center;
      cursor: pointer;
  }

  .dropdown-icon {
      transition: transform 0.3s ease;
  }

  .rotate {
      transform: rotate(180deg);
  }
}

.dropdown-input-list {
  position: absolute;
  border: 1px solid #80808091;
  border-radius: 10px;
  width: 100%;
  left: 0;
  top: 70px;
  background: white;
  max-height: 0; // Initial height for transition
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;

  &.open {
      max-height: 120px; // Adjust to your dropdown's maximum height
      opacity: 1;
  }

  &::-webkit-scrollbar {
      width: 2px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #c0bbbb;
      border-radius: 4px;
  }
}

.dropdown-input-item {
  padding: 10px;
  border-bottom: 1px solid #80808036;

  &:hover {
      background: #f0f0f0; // Replace with your desired hover color
      cursor: pointer;
  }
}

@media screen and (max-width: 450px) {
  .dropdown-input-container {
      width: 100%;
  }
}
