@import '/src/theme/colors';

.radio-input-question {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: min-content;
  border: 1px solid #80808091;
  border-radius: 10px;
  padding: 10px;
  color: black;

  &_text {
    font-size: 14px;
    text-align: left;
    color: #5A5A5A;
  }

  &_options {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.form__radio-button {
  height: 20px;
  width: 20px;
  border: 2px solid $tertiaryColor;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  // transform: translateY(-2px);

  &::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transition: opacity 0.1s;
    transform: translate(-50%, -50%);
    background-color: $tertiaryColor;
    opacity: 0;
  }
}

.form__radio-input:checked+.form__radio-label .form__radio-button::after {
  opacity: 1;
}