@import '/src/theme/colors';

.form-layout {
    display: flex;
    gap: 20px;
    align-items: stretch; // Ensures children have equal height

    &-nav {
        width: 20%;
        display: flex; // Ensure it stretches vertically
        flex-direction: column; // Optional, for vertical stacking
    }

    &-content {
        flex-grow: 1;
    }
}
