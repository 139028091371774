body{
  margin: 0;
  padding: 0;
  /* background-color: #FAFBFF; */
}
.app{
  height: 100vh;
}
.app-parent-container{
  padding: 50px;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 820px) {
  .app-parent-container {
    padding-inline-start: 100px;
  }
}