@import '/src/theme/colors';
.form-nav {
    border-right: 1px solid #80808073;
    display: flex;
    flex-direction: column;
    color: black;
    height: 100%;
    &-item {
        display: flex;
        justify-content: start;
        gap: 10px;
        padding: 20px;
        cursor: pointer;
        align-items: center;
        transition: all 0.5s ease;
        &.active{
            background-color: white;
            color: $titleColor;
            border-right: 2px solid #2B9448;
        }
        &:hover {
            background: white;
            transform: scale(1.02);
        }
        &-number {
            background: #8080803d;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s ease;
            &.active{
                background: #2B9448;
            }
        }

        &-text {
            // white-space: nowrap;
            text-align: left;
        }
    }
}

