.responsive-table-container {
  overflow-x: auto;
  box-shadow: 0px 0px 2px 1px rgb(166 166 166);
  border-radius: 10px;
  color: black;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;

  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;


    th,
    td {
      text-align: left;
      padding-inline: 10px;
      padding-block: 20px;
      white-space: nowrap;

      &.sticky {
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }

    th {
      background: #F4F4F4;
      color: #5A5A5A;
      font-weight: 500;
    }
    td {
      background: white;
      
    }

    tbody tr {
      border-top: 1px solid #ccc;
    }
    tbody tr:hover {
      background: #eaeaea;
    }
  }
}