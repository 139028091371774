@import "/src/theme/colors";
.incident-info {
    padding: 20px;
    display: flex;
    gap: 30px;

    &-event {
      width: 85%;

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
          display: flex;
          gap: 30px;

          &-title {
            font-weight: 500;
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }

        &-right {
          display: flex;
          gap: 20px;

          &-risk_score {
            display: flex;
            gap: 5px;
            font-weight: 500;

            &-key {
            }

            &-value {
              border: 1px solid;
              padding: 10px;
              border-radius: 5px;
            }
          }

          &-action {
          }
        }
      }

      &-mid {
        display: flex;
        margin-block-start: 20px;
        margin-block-end: 40px;
        gap: 30px;

        &-item {
          display: flex;
          flex-direction: column;
          gap: 5px;

          &:nth-child(1) {
            margin-right: auto; // Push the next items to the right
          }

          &-key {
            color: #5a5a5a;
          }

          &-value {
            // font-weight: 500;
          }
        }
      }

      &-bottom {
        &-description {
          display: flex;
          flex-direction: column;
          gap: 5px;

          &-key {
            color: #5a5a5a;
          }

          &-value {
          }
        }
      }
    }

    &-people {
      width: 15%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-item {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-key {
          color: #5a5a5a;
        }

        &-value {
          // font-weight: 500;
        }
      }
      &-add_manager {
        display: flex;
        align-items: center;
        width: max-content;
        border: 1px solid #80808091;
        padding: 10px;
        border-radius: 5px;
        gap: 5px;
        color: gray;
        & svg {
          color: $tertiaryColor;
        }
      }
    }
  }