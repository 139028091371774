@import '/src/theme/colors';

.datetime-input {
  border: 1px solid #80808091;
  height: 65px;
  padding: 10px;
  font-size: 14px;
  border-radius: 10px;
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    text-align: left;
    font-size: 14px;
    color: #5A5A5A;
  }

  input {
    background-color: white;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }

  input:focus {
    padding: 2px;
  }
}
