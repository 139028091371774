@import "/src/theme/colors";
.incident-actions {
  display: flex;
  flex-direction: row-reverse;
  height: 100%; 
  border-top: 1px solid #80808091;
  &-content {
    width: 83%;
    padding: 20px;
    border-right: 1px solid #80808091;

    &-header {
      margin-bottom: 20px;
      &-title {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
