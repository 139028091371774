@import "/src/theme/colors";
.incident-details {
  &-title {
    margin-bottom: 30px;
    font-size: 24px;
    color: $titleColor;
    font-weight: 600;
  }

  &-body {
    color: black;
    background: white;
    border: 1px solid #80808091;
    border-radius: 10px;
  }
}
