@import '/src/theme/colors';

.button-tabs-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.button-tabs {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    white-space: nowrap;
    
    &::-webkit-scrollbar {
        display: none;
    }
}

.button-tabs-item {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    height: 35px;
    padding: 10px;
    border: 1px solid grey;
    color: black;
    border-radius: 5px;
    background: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;

    &.button-tabs-item-active {
        background-color: #ECFDFF;
        border: 2px solid $primaryButtonColor;
    }
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8); 
    border: none;
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: background 0.3s;
    width: 35px; 
    height: 40px;

    &:hover {
        background: rgba(240, 240, 240, 0.9);
    }

    &.left {
        left: 0;
        color: #000000;
        background: linear-gradient(to left, rgb(255 255 255 / 62%), rgb(232 232 232));
        border-radius: 5px 0px 0px 5px;
        
    }

    &.right {
        right: 0;
        color: #000000;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.62), rgb(232 232 232));
        border-radius: 0px 5px 5px 0px;
    }
}
