.comments {
  &-input {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    border: 1px solid #80808091;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    &-user {
      display: flex;
      align-items: center;
      gap: 10px;

      &-name {
        font-weight: 500;
      }
      &-avatar {
        color: gray;
      }
    }
    &-textbox {
      width: 100%;
      textarea {
        width: 100%;
        padding: 8px;
        border: none;
        border-bottom: 1px solid #80808091;
        resize: none;
        min-height: 50px;
        outline: none;
      }
    }
    &-button {
      width: 100%;
      display: flex;
      justify-content: end;

      &:hover {
      }
    }
  }

  &-list {
    margin-top: 15px;
    &-item {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      &-content {
        flex: 1;

        h4 {
          margin: 0;
          font-size: 14px;
          color: #333;

          .comments-time {
            font-size: 12px;
            color: gray;
          }
        }

        p {
          margin: 5px 0 0;
          font-size: 14px;
        }
      }
    }
  }
}
