@import '/src/theme/colors';

.section-content {
    border: 1px solid #80808091;
    border-radius: 10px;
    background: white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}